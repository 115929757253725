import { ProductCardSimple } from 'components/Product/ProductCard';
import { useProductSelector } from 'features';
import useProductCard from 'hooks/useProductCard';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { IGroupingProducts } from 'types/ContentfulTypes';
import { shuffleArray } from 'utils/arrayUtil';
import Box from '../../../Box/Box';
import Flex from '../../../Flex/Flex';
import HeaderSimple from '../../../HeaderSimple/HeaderSimple';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import { StyledGrid } from './ContentfulProductTiles.styled';

interface ContentfulProductTilesProps {
  className?: string;
  content: IGroupingProducts;
  hideTitle?: boolean;
}

const ContentfulProductTiles = ({ className, content, hideTitle }: ContentfulProductTilesProps) => {
  const { mapProductCardSimpleProps } = useProductCard();

  const { callToAction, products: productSelector, randomizeProductsOrder, showSignings, title } = content.fields;

  const { data: products } = useProductSelector({
    selector: productSelector,
  });

  const randomizedProducts = randomizeProductsOrder ? shuffleArray(products) : products;

  return (
    <>
      {!hideTitle && title && (
        <Flex alignItems="center" flexWrap="wrap" gap={200} marginBottom={100}>
          <HeaderSimple marginBottom={0} title={title} titleTag="h2" titleType="h3" />
          <ContentfulCallToAction content={callToAction} />
        </Flex>
      )}

      <Flex flexDirection="column" gap={200}>
        <Box position="relative">
          <StyledGrid className={className}>
            {randomizedProducts?.map((product, index) => {
              const { signing, ...props } = mapProductCardSimpleProps({ product });

              return (
                <ProductCardSimple
                  {...props}
                  key={`ProductCardSimple-${product?.code}-${index}`}
                  signing={showSignings ? signing : undefined}
                />
              );
            })}
          </StyledGrid>
        </Box>
      </Flex>
    </>
  );
};

export default memo(ContentfulProductTiles, isEqual);
